<template>
	<div width="100%" class="card">
		<apexchart v-if="showGraph" type="bar" height="320" :options="chartOptions" :series="series"></apexchart>
		<div v-else>
			<p style="text-align: center">{{ t('no_data') }}</p>
		</div>
	</div>
</template>

<script setup>
import { ref, watch } from 'vue';
import { useMarketStore } from '@/store/global/market';
import { storeToRefs } from 'pinia';
import { useGraphCompetitionAnalysisStore } from '@/store/dashboard/graphCompetitionAnalysis';
import { useDashboardStore } from '@/store/dashboard/dashboardStore';

// i18n
import { useI18n } from 'vue-i18n';
const { t, locale } = useI18n();
import * as apex_lang from '@/locales/apexchart_locales.js';
const loadLocale = async locale => {
	try {
		return apex_lang[locale];
	} catch (error) {
		console.error('An error occurred while fetching data: ', error);
		return apex_lang['en'];
	}
};

const marketStore = useMarketStore();
const { selectedProject, selectedMarket } = storeToRefs(marketStore);

const dashboardStore = useDashboardStore();

const graphCompetitionAnalysisStore = useGraphCompetitionAnalysisStore();
const { chartSeries } = storeToRefs(graphCompetitionAnalysisStore);

const series = ref();
const showGraph = ref(true);
const chartOptions = ref({
	chart: {
		type: 'bar',
		stacked: false,
		toolbar: {
			show: true,
			tools: {
				download: true,
				selection: true,
				zoom: true,
				zoomin: true,
				zoomout: true,
				pan: true,
				reset: true,
			},
			autoSelected: 'zoom',
		},
	},
	noData: {
		text: t('no_data'),
	},
	plotOptions: {
		bar: {
			horizontal: false,
			endingShape: 'rounded',
			columnWidth: '20%',
		},
	},
	dataLabels: {
		enabled: false,
	},
	colors: ['#5766da', '#1ecab8', '#fbb624', '#fa5c7c', '#994C00', '#660066'],
	series: [],
	tooltip: {
		enabled: true,
	},
	xaxis: {
		type: 'datetime',
		axisBorder: {
			show: true,
			color: '#bec7e0',
		},
		axisTicks: {
			show: true,
			color: '#bec7e0',
		},
		tooltip: {
			enabled: true,
		},
		labels: {
			datetimeUTC: false,
		},
	},
	yaxis: {
		title: {
			text: 'score',
			//TODO add (what is the unit ?)
		},
		decimalsInFloat: false,
	},
	fill: {
		opacity: 1,
	},
	legend: {
		position: 'right',
		horizontalAlign: 'left',
		offsetY: 70,
	},
	grid: {
		show: true,
		row: {
			colors: ['transparent', 'transparent'],
			opacity: 0.2,
		},
		borderColor: '#f1f3fa',
		padding: {
			right: 60,
			left: 60,
		},
	},
});
const chartLang = await loadLocale(locale.value);
chartOptions.value = {
	...chartOptions.value,
	chart: {
		locales: [chartLang],
		defaultLocale: locale.value,
	},
};

const fetchData = async () => {
	await graphCompetitionAnalysisStore.fetchData(selectedMarket.value, selectedProject.value).then(() => {
		if (chartSeries.value.length === 0) {
			showGraph.value = false;
		} else {
			showGraph.value = true;
		}

		series.value = chartSeries.value.filter(competitor => competitor.name !== null);
	});
};

await fetchData();
</script>

<style scoped>
.card {
	background-color: #fff;
	border-radius: 1rem;
	padding: 1.5rem 0.5rem 1rem;
	border: 2px solid #eeede3;
	min-width: 250px;
	flex-grow: 1;
}

.title {
	width: 100%;
	display: flex;
	justify-content: space-between;
	gap: 1rem;
	margin-top: 1rem;

	h1 {
		font-size: 1.7rem;
		font-weight: 500;
	}

	h2 {
		margin: 0.7rem 0 0.7rem 0;
		font-size: 1.4rem;
		font-weight: 500;
	}

	p {
		font-size: 0.85rem;
		font-weight: 500;
		font-style: italic;
		color: var(--gris-clair-fonce-semactic);
		line-height: 2.3rem;
	}
}
</style>
